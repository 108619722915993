/* Add here all your css styles (customizations) */

// fonts
.homni-container {
  width: calc(100vw - 500px);
  margin: auto;
  // overflow: hidden;

  @media screen and (max-width: 1500px) {
    width: calc(100vw - 150px);
  }

  // @media screen and (max-width: 768px) {
  //   width: calc(100vw - 150px);
  // }

  @media screen and (max-width: 576px) {
    width: calc(100vw - 40px);
  }
}


$hl_main_font:"roboto",
"san serif",
"";

// colors variables

$hl_theme_color_green: #254832;
$hl_theme_color_lightgreen: #325D41;
$hl_theme_tag_color: #72A8B5;
$hl_theme_color_black: #000;
$hl_theme_color_light: #EAE9EA;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
a,
body {
  font-family: $hl_main_font;

  a {
    font-weight: 500 !important;
  }
}

h1,
.h1 {
  font-size: 34pt !important;
  font-weight: bold !important;
  color: $hl_theme_color_black;
}

h2,
.h2 {
  font-size: 28pt !important;
  font-weight: bold !important;
  color: $hl_theme_color_black;
}

h3,
.h3 {
  font-size: 22pt !important;
  font-weight: bold !important;
  color: $hl_theme_color_black;
}

h4,
.h4 {
  font-size: 18pt !important;
  font-weight: 500 !important;
  color: $hl_theme_color_black;
  line-height: 35px !important;
}

h5,
.h5 {
  font-size: 16pt !important;
  font-weight: 500 !important;
  color: $hl_theme_color_black;
  line-height: 30px !important;
}

h6,
.h6 {
  font-size: 14pt !important;
  font-weight: 500 !important;
  color: $hl_theme_color_black;
  line-height: 28px !important;
}

p {
  font-size: 14pt !important;
  font-weight: normal !important;
  color: $hl_theme_color_black;
}

li,
a {
  font-size: 14pt !important;
  font-weight: normal !important;
  color: $hl_theme_color_black;
}

.hl-transition {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.hl-bg-light {
  background-color: $hl_theme_color_light;
}

.g-color-primary {
  color: $hl_theme_color_green !important;
}

.u-btn-primary {
  background-color: $hl_theme_color_green !important;

  &:hover {
    background-color: $hl_theme_color_lightgreen !important;
    border-color: $hl_theme_color_lightgreen !important;
  }
}

.u-btn-outline-black {
  color: #0D0D0E;
}



.btn {
  border-radius: 0px !important;
  font-weight: 500 !important;

}

.g-bg-tag {
  background-color: $hl_theme_tag_color;
}

// @override colors
/*------------------------------------
  Background Colors
------------------------------------*/
.g-bg-main-light-v1 {
  background-color: #1d1d1f;
}

.g-bg-main-light-v2 {
  background-color: #2b2d30;
}

.g-bg-main-light-v3 {
  background-color: #142538 !important;
}

/*------------------------------------
	Focus state background
  ------------------------------------*/
.g-bg-main-light-v2--focus:focus {
  background-color: #2b2d30;
}

/*------------------------------------
	Border Colors
  ------------------------------------*/
.g-brd-primary {
  border-color: $hl_theme_color_green !important;
}

.u-nav-v1-1 .nav-link.active {
  background-color: #F1E6E6 !important;
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active {
  color: $hl_theme_color_green;
}

.g-color-primary--hover:hover {
  color: $hl_theme_color_green !important;
}


.g-brd-secondary-light-v1 {
  border-color: #707070 !important;
}

.g-brd-right--md {
  border-right: solid 1px #ddd !important;
}

// ecommerce override css for profile page sidenav
.g-color-text {
  color: #0D0D0E;
}

p {
  color: #0D0D0E;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0D0D0E;
}

// Product Detail Slick slider with Thumb Dot
.slick-thumb {
  bottom: -60px;

  li {
    width: 60px;
    height: 60px;
  }
}

/**
################################ SLICK SLIDER ####################################### 
*/

// OVERRIDING SLICK SLIDER - DEFAULT PROPERTY
.slick-slide {
  // opacity: 0.4;
  transition: 0.3s ease all;

  &:hover {
    cursor: pointer;
  }

  &.slick-active.slick-current {
    opacity: 1;
  }
}

// SLICK SLIDER - CUSTOM ARROW
.slick_custom_arrow {
  // height: 100%;
  background-color: transparent;
  // opacity: 0.3;
  width: 40px;
  justify-content: center;
  transition: 0.5s ease all;
  z-index: 99;
  display: flex !important;
  align-items: center;

  &:hover {
    opacity: 0.4 !important;
  }

  &.prev_arrow {
    left: 5px !important;

    @media screen and (max-width: 576px) {
      left: 5px !important;
    }

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      text-align: center;
      color: #ccc9c9;
      font-size: 44px;
      background-color: transparent;
      vertical-align: top;
      transform: rotate(90deg);

      @media screen and (max-width: 576px) {
        height: 35px;
        width: 35px;
        font-size: 15px;
      }
    }
  }

  &.next_arrow {
    right: 5px !important;

    @media screen and (max-width: 576px) {
      right: 5px !important;
    }

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      text-align: center;
      color: #ccc9c9;
      font-size: 44px;
      background-color: transparent;
      vertical-align: top;
      transform: rotate(-90deg);
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 576px) {
        height: 35px;
        width: 35px;
        font-size: 15px;
      }
    }
  }
}

// Product Detail -- Product Desctiption -- Read More/Less Session
.product_detail {
  transition: 0.5s ease all;

  &.show_partial_desc {
    max-height: 200px;
    overflow: hidden;
  }
}

// .product-size {
//   font-size: 1.5rem !important;
// }
.overlay {
  background-color: #262729;
  /* Black background with opacity */
  overflow: hidden;
}

.product-detail-review {
  bottom: 15px;
}

.product-blog-link {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-pricing-story {
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }

  p {
    color: #fff;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  h2 {
    color: #fff;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}

.product-pricing-detail {
  h5 {
    @media screen and (max-width: 768px) {
      font-size: 20px !important;
    }
  }
}



.spices-link {
  text-decoration: underline;
}




// .product-recipe-wrapper {
//   padding: 0 15rem;
//   @media screen and (max-width: 576px ){
//     padding: 15px 15px;
//   }
// }

// landing page banner css
.banner-card-wrapper {
  @media screen and (max-width: 576px) {
    left: 1% !important;
    min-height: 527px !important;
    min-width: 350px !important;
    max-height: 558px !important;
    max-width: 400px !important;
  }

  @media screen and (min-width: 320px) and (max-width: 424px) {
    left: 1% !important;
    min-height: 527px !important;
    min-width: 300px !important;
    max-height: 558px !important;
    max-width: 300px !important;
  }

  .banner-card-tittle {
    @media screen and (min-width: 320px) and (max-width: 424px) {
      font-size: 20px;
      min-width: 200px !important;
    }
  }
}

.homepage-recipe-slider {
  .slick_custom_arrow.next_arrow {
    right: 0 !important;

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      height: 50px;
      width: 50px;
      // line-height: 45px;
      text-align: center;
      color: #0D0D0E;
      font-size: 50px;
      vertical-align: top;
      transform: rotate(-90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }

  .slick_custom_arrow.prev_arrow {
    left: 0 !important;

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      text-align: center;
      color: #0D0D0E;
      border-radius: 50%;
      font-size: 50px;
      vertical-align: top;
      transform: rotate(90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
}


.product-group-slider-wrapper {

  .slick_custom_arrow.next_arrow {
    right: 0 !important;

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      height: 50px;
      width: 50px;
      line-height: 45px;
      text-align: center;
      color: #0D0D0E;
      font-size: 50px;
      vertical-align: top;
      transform: rotate(-90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 95px;
    }
  }

  .slick_custom_arrow.prev_arrow {
    left: 0 !important;

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      text-align: center;
      color: #0D0D0E;
      border-radius: 50%;
      font-size: 50px;
      vertical-align: top;
      transform: rotate(90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 95px;
    }
  }
}

// product detail page

// Sidecart
.sidecart_overlay {
  &.active {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000082;
    z-index: 9;
  }
}

.hmn_sidecart {
  position: fixed;
  width: 300px;
  height: 100%;
  right: -100%;
  top: 0;
  background-color: #fff;
  z-index: 10;
  transition: 0.3s ease all;

  &.active {
    right: 0;
  }
}

.cart_qty {
  background: transparent;
  padding: 1px 12px;
  border: none;
  margin: 0 !important;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.sidecart_body {
  position: relative;
  height: calc(100vh - 148px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

// cart
.cart-wrapper {
  position: relative;
  max-height: 65vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cart-table-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0px 10px 14px rgba(127, 149, 143, 0.15);

  th {
    @media screen and (max-width: 567px) {
      font-size: 16px !important;
    }
  }
}

// Donate Btn styles
.donate-btns {
  button {
    font-size: 14px;
    border: 1px solid #ccc;
    margin-right: 10px;

    &.selected {
      background: #41b18f;
      color: #fff;
      font-weight: bold;
    }
  }

  .custom_donate {
    border: 1px solid #ccc;
    padding: 2px;
    border-radius: 3px;
    font-size: 14px;
    vertical-align: middle;

    span {
      margin-left: 5px;
    }

    input {
      border: 0;
      background: transparent;

      &:focus {
        outline: none;
        border: 0;
      }
    }

    &.selected {
      background: #41b18f;
      color: #fff;
      font-weight: bold;

      input {
        font-weight: bold;
        color: #fff;
      }

      ::placeholder {
        color: #fff;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
      }
    }

    // remove arrows from input number
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

// Gift Option Css
.post_card_price {
  position: absolute;
  background-color: #fff;
  bottom: 2px;
  right: 2px;
  padding: 0px 10px;
  border-radius: 5px;
  color: #41b18f;
  font-weight: bold;
}

.post_card_wrap {
  border: 3px solid transparent;
  border-radius: 5px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    border-color: #41b18f;
  }

  .post_card_tick {
    color: #41b18f;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #fff;
    border-radius: 50%;
    padding: 0px 4px;
  }
}

.remove_code {
  &:hover {
    cursor: pointer;
    color: #ccc;
  }
}

.empty-cart-wrapper {
  img {
    height: 40rem;
  }
}

// 404 page css

.error-page-wrapper {
  img {
    height: 40rem;
  }
}

li {
  list-style: none;
}

// privacy page
[role="tablist"]:not([data-tabs-mobile-type="slide-up-down"]):not([data-tabs-mobile-type="accordion"]):not([data-scroll]) {
  display: grid;
}


@media (max-width : 767px) {

  [data-tabs-mobile-type="slide-up-down"],
  [data-tabs-mobile-type="accordion"] {
    display: block;
  }
}

.fa-pagelines {
  position: relative;
  top: 23%;
  left: 28%;
  font-size: 35px;
}

.u-header--sticky-top {
  background: $hl_theme_color_lightgreen;
  box-shadow: 0 0 6px 3px #254832;
}

.hp_pro_cat .cat {
  a {

    font-weight: bold;
    color: #fff;
    text-shadow: 2px 2px 7px #1c1919;
  }

  transition: .3s ease all;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);

    a {
      text-decoration: none;
    }
  }
}

// .product-index-background {
//   background-image: ;
// }

// loader
.loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}

.dot {
  width: 24px;
  height: 24px;
  background: #3ac;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #32aacc;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #64aacc;
}

.dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #96aacc;
}

.dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #c8aacc;
}

.dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #faaacc;
}

@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@-o-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}


.home-category {
  img {
    max-height: 450px;
    min-height: 450px;
    object-fit: cover;
  }
}

// footer css

.footer-copyright {
  background-color: $hl_theme_color_lightgreen;
}

.newsletter-wrapper {
  .form-control {
    background-color: #e8e8e8;
    border-radius: 0px;
  }

  span {
    .btn {
      border-radius: 0px;
    }
  }
}

.mission-wrapper {
  .mission-first-para {
    @media screen and (max-width: 576px) {
      margin-bottom: 50px;
    }

    @media (min-width: 577px) and (max-width: 768px) {
      margin-bottom: 50px;
    }
  }

  .mission-column {
    @media screen and (max-width: 576px) {
      margin-bottom: 50px;
    }
  }

  img {
    width: 50px;
    height: auto;
  }

  h3 {

    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }

  p {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }

  .mission-button {
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }

  .icon {
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }
}


.commitment-mission-wrapper {
  .mission-first-para {
    @media screen and (max-width: 576px) {
      margin-bottom: 50px;
    }

    @media (min-width: 577px) and (max-width: 768px) {
      margin-bottom: 50px;
    }
  }

  .mission-column {
    @media screen and (max-width: 576px) {
      margin-bottom: 50px;
    }
  }

  img {
    width: 40px;
    height: 40px;
  }

  h3 {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }

  p {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }

  .mission-button {
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }

  .icon {
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }
}

.heritage-wrapper {
  background-color: #edefeb;

  h3 {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }

  p {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }

  .hero-banner-text {
    z-index: 1;
    position: relative;

  }

  .heritage-map-image {
    background-color: #fff;

    img {
      display: flex;
      align-items: center;
    }
  }
}

.slick-dots {
  display: none !important;
}

.about-slider {
  .slick-dots {
    display: none !important;
  }
}

.about-illustration-text {
  &::after {
    position: absolute;
    display: inline-block;
    content: " \21AA ";
    transform: translateY(-1.8rem);
    top: 0;
    left: 0;
    font-size: 50px;
  }
}

// recipe

.recipe-wrapper {
  margin: 0;
  position: relative;
  overflow: hidden;
  transition: .5s ease all;
  background-color: #0D0D0E;

  img {
    transition: .5s ease all;
    width: 100%;
    object-fit: none;
    opacity: 0.5;
  }

  .recipe-showcase {
    position: absolute;
    bottom: -10px;
    width: 100%;
    padding: 5% 5%;
    overflow: hidden;
    transition: .95s ease;
    transform: translateY(40%);

    a:hover {
      text-decoration: none;
    }

    .recipe-showcase-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      margin: 0;
      opacity: 0;
      transition: .5s ease all;

      p {
        margin: 0;
        display: none;
        color: white;


        &:first-of-type {
          display: block;
        }
      }
    }
  }

  &:hover {
    img {
      transform: scale(1.12);
    }

    .recipe-showcase {
      transform: translateY(0);
      transform: translateY(0);

      .recipe-showcase-description {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 576px) {
    margin-bottom: 3rem;
  }
}


// header

header {

  background-color: $hl_theme_color_lightgreen;

  .homni-container {
    overflow: initial;
  }
}

.shipping-discount {
  background-color: $hl_theme_color_green;
}

.homesilder-image-wrapper {
  background-color: #0D0D0E;

  img {
    height: 200px;
    object-fit: cover;
    opacity: 0.7;
  }
}

.header-link {
  a {
    font-size: 16px !important;
  }
}

.product-block-wrapper {
  .btn {
    width: fit-content;
  }

  h4 {
    &:before {
      border-bottom: 1px solid black;
      content: "";
      position: relative;
    }
  }
}

.product-block-wrapper {
  .btn {
    width: fit-content;
  }

  .product-block-tag {
    height: 65px;
    width: 65px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    background-color: #72A8B5;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 2% 2%;
  }
}

// Navbar brand update 
.navbar-brand {
  position: absolute;
  right: -130px;
}

.head_second {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 50px;
}

// Product Slider -- Home page 

.cat_vertical_slider {

  .sld_image {
    max-width: 200px;
    height: 118px;
    overflow: hidden;
    border-radius: 5px;
  }

  .cat_heading {
    font-weight: bold !important;
    text-transform: uppercase;
    line-height: 18pt;
    margin-top: 10px !important;
  }


}

.custom_arrow {
  text-align: center;
  position: absolute;
  right: 0;
  left: 40%;

  width: fit-content;

  &.prev {
    top: -50px;
  }

  &.next {
    bottom: -25px;
  }

  i {
    border: 1px solid $hl_theme_color_black;
    padding: 9px;
    border-radius: 50%;
  }

  &:hover {
    color: $hl_theme_color_green;
    cursor: pointer;
  }
}

.cat_horizontal_slider_wrap {

  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100% !important;

    .slick-slide,
    .slick-slide>div {
      height: 100% !important;
    }
  }
}

.cat_horizontal_slider {
  display: flex !important;
  overflow: hidden;


  .wrapper {
    img {
      max-width: 100%;
    }
  }

  .cat_image {
    border-radius: 5px;
  }

  .cat_desc {
    align-items: center;
    display: flex;
    flex-flow: wrap;

    button {
      font-weight: bold;
    }

    .cat_desc_inner_wrap {
      .h2 {
        text-transform: uppercase;
      }
    }
  }
}

// Product Collection Nav Tab 
.pc_tab_nav {

  display: flex !important;
  padding-bottom: 50px;

  .nav-item {
    font-family: $hl_main_font !important;

    text-transform: uppercase !important;
    font-size: 14pt;
    font-weight: 500 !important;
    color: $hl_theme_color_black !important;
    padding: 0 !important;
    margin: 0px 10px;
    transition: .5s ease all;
    border-bottom: 1px solid transparent;

    &.react-tabs__tab--selected {
      border-bottom: 1px solid $hl_theme_color_black;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

// Add to Bag button 
.atbg_btn {
  font-weight: bold;
}

.recipe-page-wrapper {
  img {
    max-height: 600px;
    object-fit: cover;
  }
}

.preparation-list {
  ol {
    counter-reset: orderedlist;

    li {
      position: relative;
      margin-bottom: 30px;

      &:before {
        counter-increment: orderedlist;
        content: counter(orderedlist);
        margin-right: 10px;
        border-radius: 50%;
        background-color: #DE8730;
        color: #fff;
        height: 25px;
        width: 25px;
        display: inline-block;
        text-align: center;
        line-height: 25px;
        position: absolute;
        top: 5px;
        left: -40px;
        font-size: 14px;
      }
    }
  }
}

.pro-cat-slider-wrapper .slick-slide {
  height: 200px;
}


.hide-top-bar {
  transform: translateY(-40px);
}

.shipping-discount {
  transition: .5s ease all;
}

.home_banner_slider_wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .hero-banner-text {
    z-index: 1;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    text-align: center;
    padding: 30px;
    position: absolute;
    bottom: 140px;
    left: 140px;

    @media screen and (max-width: 576px) {
      left: 40%;
      padding: 15px;
      bottom: 40%;
      transform: translate(-30%, -40%)
    }

    h1 {
      font-weight: 600 !important;

      // @media screen and (max-width: 576px) {
      //   font-size: 18px !important;
      //   text-align: center;
      //   margin-bottom: 20px !important;
      //   font-weight: 500 !important;
      // }

      // @media (min-width: 577px) and (max-width: 768px) {
      //   font-size: 28px !important;
      //   text-align: center;
      //   margin-bottom: 20px !important;
      // }

      // @media (min-width: 769px) and (max-width: 1028px) {
      //   font-size: 32px !important;
      //   margin-bottom: 20px !important;
      // }

      // @media (min-width: 1029px) and (max-width: 1440px) {
      //   font-size: 40px !important;
      //   margin-bottom: 20px !important;
      // }

      // @media (min-width: 1441px) and (max-width: 1700px) {
      //   font-size: 42px !important;
      //   margin-bottom: 20px !important;
      // }
    }

    .btn {
      @media screen and (max-width: 576px) {
        font-size: 10px !important;
        text-align: center;
      }

      @media (min-width: 577px) and (max-width: 768px) {
        font-size: 12px !important;
        text-align: center;
      }

      @media (min-width: 767px) and (max-width: 1028px) {
        font-size: 14px !important;
      }
    }
  }
}

.contact-container {
  bottom: -20%;
  left: 51%;
  transform: translate(-51%, 20%);

  @media screen and (max-width: 576px) {
    bottom: 15%;
  }

  @media (min-width: 577px) and (max-width: 768px) {
    bottom: 17%;
  }
}

.contact-page-media {
  background-color: #fff;
  box-shadow: 0px 5px 32px rgba(197, 197, 197, 0.5);
}


// responsiveness

.home-banner-btn {
  @media screen and (max-width: 576px) {
    justify-content: center;
  }

  @media (min-width: 577px) and (max-width: 768px) {
    justify-content: center;
  }

}

.mission-first-para-column {
  @media (min-width: 577px) and (max-width: 768px) {
    display: flex;
    align-items: center;
  }
}

.heritage-product-column {
  @media screen and (max-width: 576px) {
    margin-bottom: 50px !important;
  }
}

.newsletter-wrapper {
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }

  h3 {
    @media screen and (max-width: 576px) {
      text-align: center;
    }

  }

  p {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }
}

footer {
  .nav {
    @media screen and (max-width: 576px) {
      display: block !important;
      text-align: center;
    }
  }
}


.login-user {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
  background-color: #72A8B5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.cart-media-wrapper {
  h6 {
    @media screen and (max-width: 768px) {
      font-size: 18px !important;
      margin-top: 15px;
    }
  }
}

.step-progress-wrapper {
  h4 {
    @media screen and (max-width: 576px) {
      font-size: 14px !important;
      line-height: 25px !important;
    }

    @media (min-width: 577px) and (max-width: 768px) {
      font-size: 16px !important;
      line-height: 25px !important;
    }
  }
}



// // sidecart
// .sidecart_save_for_later {
//   // background-color: #244F68;
// }

.save_for_later_wrapper {
  background-color: #244F68;

  p {
    font-size: 16px !important;
  }
}

.save_cart {
  height: calc(100vh - 452px);
  overflow-y: overlay;
}

.save-cart_visible {
  height: calc(100vh - 548px);
}


.sidecart_item_wrapper {
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidecart_size_dropdown {
  p {
    font-size: 14px !important;
  }

  .btn {
    font-size: 14px !important;
  }
}

.dropdown-menu {
  min-width: 5rem !important;
}

.size-dropdown-position {
  transform: translate3d(0px, 23px, 0px) !important;
}

// Account Verification 
.verify-change {
  &:hover {
    cursor: pointer;
  }
}

// Mobile header responsive 
.mobile-logo {
  display: flex !important;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
  }
}


// Product Detail Quantity Change 
.cart_quantity_change {
  font-size: 18px !important;
}

.faqs p {
  color: #777 !important;
  font-size: 1.3rem !important;
  font-weight: 400 !important;

}

.product-detail-story {
  p {
    font-size: 12pt !important;
  }
}

.view-recipe {
  font-size: 13px !important;
  text-transform: uppercase;
  color: #fff;
}

// Mobile Responsive 
.mobile_search {
  position: absolute;
  left: 0;
  bottom: calc(-100% - 18px);
  z-index: 99;
  display: flex;
  align-items: center;
  width: 500px;
  max-width: calc(100vh - 50px);

  &.hidden {
    display: none;
  }
}

.css-x4na0v {
  opacity: 1 !important;
  // span {
  //   justify-content: center !important;
  // }
}

.blog-image {
  max-height: 300px;
  object-fit: cover;
  min-height: 300px;
}

.blog-listing-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.new-blog-section {
  img {
    height: 300px;
    object-fit: cover;
  }
}

.recipe-image-upload {
  background-color: #FFF1EA;
}

.uploaded-files {
  img {
    height: 80px;
    width: 80px;
    object-fit: cover;
  }
}

.files-uploaded-section {
  @media screen and (max-width: 990px) {
    margin-top: 30px;
  }
}

// Tabbed section 
.react-tabs__tab-panel {
  opacity: 0;
  transition: .5s ease all;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  opacity: 1;
}


.side_cart_cart_item_action {
  margin-top: 5px;

  button {
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

// recipe author 
.recipe-author {
  font-style: italic;
}

// commitment
.commitment-banner {
  background-color: #0D0D0E;

  .commitment-wrapper {
    opacity: 0.7;

    .commitment-container {
      z-index: 1;
      top: 50%;
      left: 22.5%;
      transform: translate(-25%, -50%);
    }
  }
}

.featured_recipe {
  border: 2px solid;

  span {
    font-weight: 600;
  }

  img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  .btn {
    width: fit-content;
  }
}

.repo-slider-section {
  .intresting-repo-first {
    border: 2px solid;

    .intresting-repo-first-text {
      @media screen and (max-width: 767px) {
        margin: 20px;
      }
    }

    img {
      height: 400px;
      width: 100%;
      object-fit: cover;

      @media screen and (max-width: 576px) {
        height: 250px;
      }
    }

    .btn {
      width: fit-content;
    }
  }

  .intresting-repo-second {
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
      opacity: 0.7
    }
  }
}

.grower_slider {
  padding: 0px 50px;

  .slick_custom_arrow.next_arrow {
    right: 0 !important;

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      height: 50px;
      width: 50px;
      // line-height: 45px;
      text-align: center;
      color: #0D0D0E;
      font-size: 50px;
      vertical-align: top;
      transform: rotate(-90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }

  .slick_custom_arrow.prev_arrow {
    left: 0 !important;

    &:before {
      opacity: 1;
      content: "\f107";
      font-family: "FontAwesome";
      text-align: center;
      color: #0D0D0E;
      border-radius: 50%;
      font-size: 50px;
      vertical-align: top;
      transform: rotate(90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
}

.secondsilder-image-text {
  bottom: 5px !important;
}

.intresting-slider-wrapper {
  background-color: #0D0D0E;
}

.grower-gallery-text {
  text-align: center;

  .btn {
    color: #fff;
    text-align: center;
    border-radius: 4px !important;
  }

  h5 {
    color: #fff;
    text-align: center;
  }
}


.hmm_sidecart {
  position: fixed;
  width: 600px;
  height: 100%;
  right: -100%;
  top: 0;
  background-color: #fff;
  z-index: 10;
  transition: 0.3s ease all;

  &.active {
    right: 0;
  }
}

.header {
  background-color: $hl_theme_color_lightgreen;
  position: relative;
  padding: 6px 0px;

  ul {
    li {
      a {
        font-size: 18px !important;
        text-decoration: none;
        text-align: center;
      }
    }
  }
}

.search_overlay {
  &.active {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000082;
    z-index: 9;
  }
}

.search_sidecart {
  background: #fff;
  position: fixed;
  transition: all .2s ease-in-out;
  z-index: 9;
  width: 100%;
  right: 0%;
  top: -105px;
  padding: 10px;
  transform: scale(0);

  &.active {
    right: 0;
    top: 0px;
    transform: scale(1);
  }

  form {
    .form-control {
      border: 1px solid rgba(13, 13, 14, 0.40);
      color: #0D0D0E;
      width: 50%;
      border-radius: 0px;

      @media screen and (max-width: 768px) {
        width: 80%;
      }


    }

    img {
      right: 25%;

      @media screen and (max-width: 768px) {
        right: 10%;
      }

    }
  }


}

.Producttab_slider {
  padding: 0px 50px;
}

.product_producer_detail_column {
  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
}

.recipe-list-view {
  margin-top: 30px;

  ul {
    padding: 0;

    li {
      margin-bottom: 10px;
    }
  }
}

.react-photo-gallery--gallery {
  img {
    object-fit: cover;
  }
}

.react-images__view {
  img {
    max-height: 80vh !important;
  }
}

.search_populate_dropdown {
  padding: 1rem;
  position: absolute;
  background: #fff;
  width: 50%;
  top: 43px;

  @media screen and (max-width: 576px) {
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }

  .search_item_wrapper {
    img {
      height: 60px;
      width: 60px;
      object-fit: cover;
    }

    p {
      margin-left: 10px;
      text-transform: capitalize;
      text-align: initial;
    }
  }
}



// menu mega mega

@media(min-width: 767px) {
  ul.ruby-menu>li>a:after {
    transition: all .3s ease
  }

  ul.ruby-menu>li>ul>li>a:after,
  ul.ruby-menu>li>ul>li>ul>li>a:after,
  ul.ruby-menu>li>ul>li>ul>li>ul>li>a:after {
    transition: all .3s ease
  }

  ul.ruby-menu>li>ul,
  ul.ruby-menu>li>div {
    transform: translateY(25px);
    transition: all .3s ease
  }

  ul.ruby-menu>li:hover>ul,
  ul.ruby-menu>li:hover>div {
    transform: translateY(0)
  }

  ul.ruby-menu.ruby-vertical>li>ul,
  ul.ruby-menu.ruby-vertical>li>div {
    transform: translateX(25px);
    transition: all .3s ease
  }

  ul.ruby-menu.ruby-vertical>li:hover>ul,
  ul.ruby-menu.ruby-vertical>li:hover>div {
    transform: translateX(0)
  }

  ul.ruby-menu>li>ul>li>ul,
  ul.ruby-menu>li>ul>li>ul>li>ul,
  ul.ruby-menu>li>ul>li>ul>li>ul>li>ul {
    transform: translateX(25px);
    transition: all .3s ease
  }

  ul.ruby-menu>li>ul>li:hover>ul,
  ul.ruby-menu>li>ul>li>ul>li:hover>ul,
  ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>ul {
    transform: translateX(0)
  }

  ul.ruby-menu>li>ul>li.ruby-open-to-left>ul,
  ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>ul,
  ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left>ul {
    transform: translateX(-25px);
    transition: all .3s ease
  }

  ul.ruby-menu>li>ul>li.ruby-open-to-left:hover>ul,
  ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left:hover>ul,
  ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left:hover>ul {
    transform: translateX(0)
  }

  ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
    transform: translateY(-50px);
    transition: all .5s ease
  }

  ul.ruby-menu>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div,
  ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
    transform: translateY(0)
  }

  ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
    transform: translateX(50px);
    transition: all .5s ease
  }

  ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div,
  ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
    transform: translateX(0)
  }
}

ul.ruby-menu>li>a .fa {
  position: relative;
}

ul.ruby-menu>li.ruby-menu-mega>div,
ul.ruby-menu>li.ruby-menu-mega-blog>div,
ul.ruby-menu>li.ruby-menu-mega-shop>div {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50px;
  left: 0;
  background: #fff;
  border-top: 4px solid #19ad86;
  overflow: hidden;
  -webkit-box-shadow: 0 8px 24px -5px #ccc;
  -moz-box-shadow: 0 8px 24px -5px #ccc;
  box-shadow: 0 8px 24px -5px #ccc
}

ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav {
  position: relative;
  // width: 200px;
  height: 100%;
  left: 0;
  top: 0;
}

ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li {
  font-size: 14px
}

ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a {
  display: block;
  line-height: 40px;
  padding: 0 15px;
  color: #666;
  text-decoration: none
}

ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>a {
  background: #e6e6e6
}

ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
  position: absolute;
  width: 780px;
  height: auto;
  min-height: 100%;
  left: 200px;
  top: 0;
  background: #fff
}


@media(min-width: 768px) {

  ul.ruby-menu>li.ruby-menu-mega>div,
  ul.ruby-menu>li.ruby-menu-mega-blog>div,
  ul.ruby-menu>li.ruby-menu-mega-shop>div {
    visibility: hidden;
    opacity: 0
  }

  ul.ruby-menu>li.ruby-menu-mega:hover>div,
  ul.ruby-menu>li.ruby-menu-mega-blog:hover>div,
  ul.ruby-menu>li.ruby-menu-mega-shop:hover>div {
    visibility: visible;
    opacity: 1;
    z-index: 40;
  }


}

.ruby-menu-mega-blog {
  img {
    width: 100%;
  }

  .menu_category_product {
    .h6 {
      background-color: #fff;
      padding: 5px 0px;
      background: rgba(255, 255, 255, 0.45);
      backdrop-filter: blur(10px);
      width: 100%;
      justify-content: center !important;
    }
  }
}

.menu_category_product {
  position: relative !important;
  height: 100%;
  right: -100%;
  top: 0;
  border-top: none !important;
  z-index: 10;
  transition: all 1s linear;
  box-shadow: none !important;
  display: none;

  &.active {
    right: 0;
    display: block;
  }
}

.menu {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.header {
  .logo {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 768px) {
      position: relative;
      left: 0%;
      transform: translate(-0%, -50%);
    }

    img {
      @media screen and (max-width: 576px) {
        width: 70%;
        object-fit: cover;
        height: auto;
      }
    }
  }

  .action {
    .header-wholesale {
      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    .user-login {
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  .mobile-view {
    display: none;

    .menu_overlay {
      &.active {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #00000082;
        z-index: 9;
      }
    }

    .menu_sideview {
      position: fixed;
      width: 350px;
      height: 100%;
      right: -100%;
      top: 0;
      background-color: #fff;
      z-index: 100;
      transition: 0.3s ease all;
      overflow: scroll;

      &.active {
        right: 0;
      }

      .ruby-menu-mega-blog {
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .mobile_menu_category_product {
        display: none;
        transform: scale(0);

        &.active {
          display: inline;
          transform: scale(1);
        }
      }
    }


    .header-wholesale {
      @media screen and (max-width: 576px) {
        display: inline !important;
      }

      a {
        color: #0D0D0E !important;
      }
    }

    .user-login {
      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      display: inline;
    }
  }
}


//Map Section

.mapContainerMain {
  height: 700px;
}

.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.mapboxgl-popup {
  position: relative;
  max-width: 1px !important;
  bottom: 935px;
  right: 87px;
  z-index: 999999999;
}

.mapboxgl-popup-close-button {
  position: relative;
  bottom: 195px;
  left: 175px;
  display: none;
}

.mapboxgl-popup-content {
  transform: none;
  width: 0px;
  height: 0;
}

.mapPopUp {
  position: relative;
  background-color: #ebebeb;
  width: 175px;
  height: 200px;
  padding: 15px;

  .productHeaderMap {
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 500 !important;
  }

  .mapDetails {
    font-size: 12px !important;
    line-height: 15px;
  }
}

.mapPopUp ::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ebebeb;
}

.mapboxgl-popup-close-button {
  position: relative;
  bottom: 195px;
  left: 175px;
  display: none;
}

@media screen and (max-width:1024px) {
  .mapContainerMain {
    height: 300px;
  }

  .mapboxgl-popup {
    position: relative;
    bottom: 550px;
    right: 87.5px;
  }
}

.mapPopUp {
  position: relative;
  background-color: #ebebeb;
  width: 175px;
  height: 200px;
  padding: 15px;

  .productHeaderMap {
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 500 !important;
  }

  .mapDetails {
    font-size: 12px !important;
    line-height: 15px;
  }
}

.mapPopUp ::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ebebeb;
}


@media screen and (max-width:1024px) {
  .mapContainerMain {
    height: 300px;
  }

  .mapboxgl-popup {
    position: relative;
    bottom: 530px;
    right: 100px;
  }
}

.submenu-wrap {
  background-color: #eee;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  background-position: 50% 50%;
  background-size: cover;
}


input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;

}

.timeline-icon {
  width: 80%;
  margin: 10%;
}

btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.register-check-icon {
  ::before {
    color: #fff;
  }
}

.u-check-icon-checkbox-v6,
.u-check-icon-radio-v6 {
  width: 16px;
  height: 16px;
  font-size: 12px;
}

.register-check-icon {
  .fa {
    font-size: 10px;
  }
}

a {
  text-decoration: none;

  &:hover {
    color: $hl_theme_color_lightgreen;
  }
}

.btn {
  &:hover {
    background-color: $hl_theme_color_lightgreen;
  }
}

.u-btn-primary {
  &:hover {
    background-color: $hl_theme_color_lightgreen;
  }
}


.active_term_menu{
color: black !important;
opacity: 0.8;
}
.product-menu-category {
  @media screen and (max-width:567px) {
    display: none;
  }
}

.sortby-dropdown-menu {
  @media screen and (max-width:567px) {
    left: -140px !important;
    transform: none !important;
    top: 30px !important;
  }
}

.category-wrapper-product {
  @media screen and (max-width:567px) {
    justify-content: space-between;
  }
}

.btn-success {
  color: #fff;
  background-color: $hl_theme_color_green;
  border-color: $hl_theme_color_green;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: $hl_theme_color_lightgreen;
  border-color: $hl_theme_color_lightgreen;
}

btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.recipe-menu-category {
  @media screen and (max-width:768px) {
    display: none;
  }
}

.recipe-menu-category {
  @media screen and (max-width:768px) {
    display: none;
  }
}

.about-media {
  @media screen and (max-width:768px) {
    margin-top: 15px;
  }
}

.recipe-type-repo-first-text {
  @media screen and (max-width:768px) {
    margin: 15px;
  }
}

.faq-category-section {
  @media screen and (max-width:567px) {
    display: none;
  }
}

.mobile-faq-category {
  @media screen and (max-width:567px) {
    display: inline-grid;
  }
}

.faq-page-column {
  @media screen and (max-width:567px) {
    margin-top: 0px !important;
  }
}

.dropdown {
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width:768px) {
    display: none;
  }

  .active_term_menu {
    color: black !important;
  }
}