body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.pagination-link.pagination-active a {
    background-color: "white";
    color: "red";
}

.hideScrollBar {
    -ms-overflow-style: none;
    scrollbar-width: 2px;
}

.hideScrollBar::-webkit-scrollbar {
    width: 7px;
}

.hideScrollBar::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}

.hideScrollBar::-webkit-scrollbar-thumb {
    background-color: #325d41; /* color of the scroll thumb */
}
