/*
 * Author: Digital Zoom Studio
 * Website: http://digitalzoomstudio.net/
 * Portfolio: http://codecanyon.net/user/ZoomIt/portfolio
 *
 * Version: 5.01
 */
/*functional styling*/
.scroller-con {
  position: relative;
  width: 100%;
  height: 200px;
  opacity: 0;
  transition-property: opacity, visibility, top;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: opacity, visibility, top;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: opacity, visibility, top;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: opacity, visibility, top;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: opacity, visibility, top;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .scroller-con * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .scroller-con .scroller {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .scroller-con .scroller .inner {
      position: absolute !important; }
    .scroller-con .scroller .real-inner {
      position: absolute;
      top: 0;
      left: 0; }
  .scroller-con .scrollbar .scrollbary.animatetoptoo {
    transition-property: opacity, visibility, top;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    -moz-transition-property: opacity, visibility, top;
    -moz-transition-duration: 0.3s;
    -moz-transition-timing-function: ease-out;
    -webkit-transition-property: opacity, visibility, top;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-property: opacity, visibility, top;
    -o-transition-duration: 0.3s;
    -o-transition-timing-function: ease-out;
    -ms-transition-property: opacity, visibility, top;
    -ms-transition-duration: 0.3s;
    -ms-transition-timing-function: ease-out; }
  .scroller-con .scroller::-webkit-scrollbar {
    display: none; }

.scroller-con.safe {
  opacity: 1; }

.scroller-con.type-scrollTop {
  overflow: hidden;
  height: auto; }
  .scroller-con.type-scrollTop .scrollbary_bg, .scroller-con.type-scrollTop .scrollbary {
    position: fixed; }

.scroller-con.dzsscr-inited {
  opacity: 1; }

.inner.inner-for-horizontal {
  white-space: nowrap;
  width: auto; }
  .inner.inner-for-horizontal > img {
    max-width: none; }

.scroller-con.auto-height {
  height: auto; }
  .scroller-con.auto-height .scroller {
    position: relative;
    height: auto; }
    .scroller-con.auto-height .scroller .inner {
      position: relative !important;
      display: inline-block; }
    .scroller-con.auto-height .scroller .real-inner {
      position: relative;
      top: 0;
      left: 0; }
  .scroller-con.auto-height .scroller::-webkit-scrollbar {
    display: none; }

.scroller-con.overflow-visible .scroller {
  overflow: visible; }

.scroller-con.overflow-x-visible .scroller {
  overflow: visible;
  overflow-y: hidden;
  overflow-x: visible; }

.scroller-con.easing .inner {
  transition-property: left, top;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: left, top;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: left, top;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: left, top;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: left, top;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }

.scroller-con .scrollbar.easing > * {
  transition-property: left, top;
  transition-duration: 0.7s;
  transition-timing-function: ease-out;
  -moz-transition-property: left, top;
  -moz-transition-duration: 0.7s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: left, top;
  -webkit-transition-duration: 0.7s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: left, top;
  -o-transition-duration: 0.7s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: left, top;
  -ms-transition-duration: 0.7s;
  -ms-transition-timing-function: ease-out; }

.scroller-con .scrollbary {
  position: absolute;
  cursor: pointer; }

.scroller-con .scrollbary_bg {
  position: absolute;
  cursor: pointer; }

.scroller-con .scrollbarx {
  position: absolute;
  cursor: pointer; }

.scroller-con .scrollbarx_bg {
  position: absolute;
  cursor: pointer; }

.scroller-con.swipe-enabled {
  cursor: move;
  cursor: -moz-grab;
  cursor: grab;
  cursor: url(styleimg/openhand.cur) 4 4, move; }

.scroller-con.swipe-enabled.closedhand {
  cursor: url(styleimg/closedhand.cur) 4 4, move; }

/*estethic styling*/
/* skin_default */
.scroller-con.skin_default .scrollbarx {
  width: 60px;
  height: 7px;
  left: 0;
  bottom: -10px;
  background: #5896c6;
  border-radius: 2px; }
.scroller-con.skin_default .scrollbarx_bg {
  height: 7px;
  left: 0;
  bottom: -10px;
  background: #eee;
  border-radius: 5px; }
.scroller-con.skin_default .scrollbary {
  width: 10px;
  height: 60px;
  top: 0;
  right: -11px;
  background: #5896c6;
  border-radius: 2px; }
.scroller-con.skin_default .scrollbary_bg {
  width: 10px;
  top: 0;
  right: -11px;
  background: #eee;
  border-radius: 5px; }

.scroller-con.dir-ver .scroller {
  width: calc(100% - 16px); }

.scroller-con.skin_apple.dir-ver .scroller {
  width: 100%; }

/* skin_alternate */
.scroller-con.skin_alternate {
  margin-bottom: 40px; }
  .scroller-con.skin_alternate .scrollbarx {
    width: 200px;
    height: 30px;
    left: 0;
    bottom: -30px;
    background: yellow; }
  .scroller-con.skin_alternate .scrollbarx_bg {
    height: 30px;
    left: 0;
    bottom: -30px;
    background: #000; }
  .scroller-con.skin_alternate .scrollbary {
    width: 7px;
    height: 60px;
    top: 0;
    right: -10px;
    background: transparent url("styleimg/scroller1.png"); }
  .scroller-con.skin_alternate .scrollbary_bg {
    width: 7px;
    top: 0;
    right: -10px;
    background: #eee; }

/*Skin 3*/
.scroller-con.skin_blackwhite {
  margin-top: 30px; }

.scroller-con.skin_blackwhite .scrollbarx {
  position: absolute;
  width: 35px;
  height: 10px;
  left: 0;
  top: -15px;
  background: url("styleimg/s3scrollerx.png");
  cursor: pointer; }

.scroller-con.skin_blackwhite .scrollbarx_bg {
  position: absolute;
  height: 1px;
  left: 0;
  top: -10px;
  background: #eee;
  cursor: pointer; }

.scroller-con.skin_blackwhite .scrollbary {
  width: 10px;
  height: 35px;
  top: 0;
  right: -15px;
  background: url("styleimg/s3scrollery.png"); }

.scroller-con.skin_blackwhite .scrollbary_bg {
  position: absolute;
  width: 1px;
  top: 0;
  right: -10px;
  background: #fff;
  cursor: pointer; }

/*======Skin-Progress*/
.scroller-con.skin_progress .scrollbarx {
  width: 60px;
  height: 19px;
  left: 0;
  bottom: -20px;
  background-image: url("styleimg/progress.png");
  border-radius: 15px; }

.scroller-con.skin_progress .scrollbarx_bg {
  height: 19px;
  left: 0;
  bottom: -20px;
  background: #191d21;
  border-radius: 15px;
  border: 1px solid #1a1a1a;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.scroller-con.skin_progress .scrollbary {
  width: 19px;
  height: 60px;
  top: 0;
  right: -28px;
  background-image: url("styleimg/progressy.png");
  border-radius: 15px; }

.scroller-con.skin_progress .scrollbary_bg {
  width: 19px;
  top: 0;
  right: -28px;
  background: #191d21;
  border-radius: 15px;
  border: 1px solid #1a1a1a;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/*======Skin_ProgressBox*/
.scroller-con.skin_progressbox .scrollbarx {
  width: 60px;
  height: 19px;
  left: 0;
  bottom: -16px;
  background-image: url("styleimg/progressblue.png"); }

.scroller-con.skin_progressbox .scrollbarx_bg {
  height: 19px;
  left: 0;
  bottom: -16px;
  background: #191d21;
  border: 1px solid #1a1a1a;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.scroller-con.skin_progressbox .scrollbary {
  width: 19px;
  height: 60px;
  top: 0;
  right: -28px;
  background-image: url("styleimg/progressy.png");
  border-radius: 15px; }

.scroller-con.skin_progressbox .scrollbary_bg {
  width: 19px;
  top: 0;
  right: -28px;
  background: #191d21;
  border: 1px solid #1a1a1a;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/*skin slider*/
.scroller-con.skin_slider .scrollbarx {
  width: 60px;
  height: 7px;
  left: 0;
  bottom: -10px;
  background: #5896c6;
  border-radius: 2px; }

.scroller-con.skin_slider .scrollbarx_bg {
  height: 7px;
  left: 0;
  bottom: -10px;
  background: #eee;
  border-radius: 5px; }

.scroller-con.skin_slider .scrollbary {
  width: 30px;
  height: 30px;
  top: 0;
  right: -30px;
  background-image: url("styleimg/handle.png");
  background-repeat: no-repeat;
  background-position: center center; }

.scroller-con.skin_slider .scrollbary_bg {
  width: 7px;
  top: 0;
  right: -18px;
  background: #282a31;
  border-radius: 15px;
  border: 1px solid #1a1a1a;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.scroller-con.skin_slider.scrollbars-inset .scrollbary {
  right: 0px; }
.scroller-con.skin_slider.scrollbars-inset .scrollbary_bg {
  right: 0px; }
.scroller-con.skin_slider.scrollbars-inset .scrollbarx {
  bottom: 0px; }
.scroller-con.skin_slider.scrollbars-inset .scrollbarx_bg {
  bottom: 10px; }

/*skin slider*/
.scroller-con.skin_slider .scrollbarx {
  width: 30px;
  height: 30px;
  left: 0;
  bottom: -31px;
  background-color: transparent;
  background-image: url("styleimg/handle.png");
  background-repeat: no-repeat;
  background-position: center center; }

.scroller-con.skin_slider .scrollbarx_bg {
  height: 7px;
  left: 0;
  bottom: -20px;
  background: #282a31;
  border-radius: 15px;
  border: 1px solid #1a1a1a;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.scroller-con.skin_slider .scrollbary {
  width: 30px;
  height: 30px;
  top: 0;
  right: -30px;
  background-image: url("styleimg/handle.png");
  background-repeat: no-repeat;
  background-position: center center; }

.scroller-con.skin_slider .scrollbary_bg {
  width: 7px;
  top: 0;
  right: -18px;
  background: #282a31;
  border-radius: 15px;
  border: 1px solid #1a1a1a;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/*skin skin_timeline*/
.scroller-con.skin_timeline .scrollbar {
  opacity: 0; }

.scroller-con.skin_timeline .scrollbarx {
  width: 17px;
  height: 17px;
  left: 0;
  bottom: 7px;
  background-color: transparent;
  background-image: url("styleimg/skin_timeline_handle.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.scroller-con.skin_timeline .scrollbarx_bg {
  height: 30px;
  left: 0;
  bottom: 0px;
  background-image: url(styleimg/skin_timeline_bg.png);
  background-position: center center;
  background-repeat: repeat-x;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.scroller-con.skin_timeline .scrollbary {
  width: 30px;
  height: 30px;
  top: 0;
  right: -30px;
  background-image: url("styleimg/handle.png");
  background-repeat: no-repeat;
  background-position: center center; }

.scroller-con.skin_timeline .scrollbary_bg {
  width: 7px;
  top: 0;
  right: -18px;
  background: #282a31;
  border-radius: 15px;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.scroller-con.skin_royale {
  margin-bottom: 30px; }
  .scroller-con.skin_royale .scrollbarx_bg, .scroller-con.skin_royale .scrollbary_bg {
    background-color: #1c1a17;
    border-radius: 3px; }
  .scroller-con.skin_royale .scrollbarx, .scroller-con.skin_royale .scrollbary {
    background-color: #363330;
    border-radius: 3px; }
  .scroller-con.skin_royale .scrollbarx_bg {
    height: 14px;
    top: auto;
    bottom: -24px; }
  .scroller-con.skin_royale .scrollbarx {
    width: 50px;
    height: 14px;
    top: auto;
    bottom: -24px; }
  .scroller-con.skin_royale .scrollbary_bg {
    width: 14px;
    left: auto;
    right: -24px; }
  .scroller-con.skin_royale .scrollbary {
    width: 14px;
    left: auto;
    right: -24px;
    height: 50px; }

/*======skin apple*/
.scroller-con.skin_apple .scrollbarx {
  width: 90px;
  height: 8px;
  left: 0;
  bottom: -15px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px; }

.scroller-con.skin_apple .scrollbarx_bg {
  height: 0px; }

.scroller-con.skin_apple .scrollbary {
  width: 8px;
  height: 90px;
  top: 0;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px; }

.scroller-con.skin_apple .scrollbary_bg {
  width: 8px;
  right: 0;
  background: rgba(255, 255, 255, 0);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: all;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: all;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: all;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }

.scroller-con.skin_apple .scrollbar:hover .scrollbary_bg, .scroller-con.skin_apple .scrollbar.dragging .scrollbary_bg {
  background: rgba(255, 255, 255, 0.3); }

.scroller-con.skin_bigblue {
  margin-bottom: 30px; }
  .scroller-con.skin_bigblue .scrollbary {
    width: 20px;
    background: #59c8ef;
    opacity: 0.2;
    border-radius: 0;
    height: 350px; }
  .scroller-con.skin_bigblue .scrollbary_bg {
    opacity: 0;
    border-radius: 0; }
  .scroller-con.skin_bigblue .scrollbarx {
    width: 350px;
    background: #59c8ef;
    opacity: 0.2;
    border-radius: 0;
    height: 20px;
    top: 100%;
    bottom: auto;
    margin-top: 5px; }
  .scroller-con.skin_bigblue .scrollbax_bg {
    opacity: 0;
    border-radius: 0;
    top: 100%;
    bottom: auto;
    margin-top: 5px; }

.scroller-con.skin_luna {
  margin-bottom: 30px; }
  .scroller-con.skin_luna .scrollbary {
    width: 20px;
    background: #59c8ef;
    opacity: 0.2;
    border-radius: 0;
    height: 350px; }
  .scroller-con.skin_luna .scrollbary_bg {
    opacity: 0;
    border-radius: 0; }
  .scroller-con.skin_luna .scrollbarx {
    width: 100px;
    background: #fafafa;
    opacity: 1;
    border-radius: 0;
    height: 16px;
    top: 100%;
    bottom: auto;
    margin-top: 7px;
    margin-left: 2px; }
    .scroller-con.skin_luna .scrollbarx svg.arrow-left, .scroller-con.skin_luna .scrollbarx svg.arrow-right {
      width: 5px;
      height: 7px;
      position: absolute;
      top: 5px;
      left: 6px; }
      .scroller-con.skin_luna .scrollbarx svg.arrow-left path, .scroller-con.skin_luna .scrollbarx svg.arrow-right path {
        fill: #555; }
    .scroller-con.skin_luna .scrollbarx svg.arrow-right {
      right: 6px;
      left: auto;
      transform: scaleX(-1); }
  .scroller-con.skin_luna .scrollbarx_bg {
    opacity: 1;
    border-radius: 0;
    background-color: rgba(50, 50, 50, 0.1);
    height: 20px;
    top: 100%;
    bottom: auto;
    margin-top: 5px; }

.scroller-con.skin_cerc {
  margin-bottom: 30px; }
  .scroller-con.skin_cerc .scrollbary {
    width: 20px;
    background: #59c8ef;
    opacity: 0.2;
    border-radius: 0;
    height: 350px; }
  .scroller-con.skin_cerc .scrollbary_bg {
    opacity: 0;
    border-radius: 0; }
  .scroller-con.skin_cerc .scrollbarx {
    width: 22px;
    background: #444444;
    opacity: 1;
    border-radius: 50%;
    height: 22px;
    top: 100%;
    bottom: auto;
    margin-top: 7px;
    margin-left: 2px; }
    .scroller-con.skin_cerc .scrollbarx svg.arrow-left, .scroller-con.skin_cerc .scrollbarx svg.arrow-right {
      width: 3px;
      height: 7px;
      position: absolute;
      top: 8px;
      left: 5px; }
      .scroller-con.skin_cerc .scrollbarx svg.arrow-left path, .scroller-con.skin_cerc .scrollbarx svg.arrow-right path {
        fill: #e5c100; }
    .scroller-con.skin_cerc .scrollbarx svg.arrow-right {
      right: 5px;
      left: auto;
      transform: scaleX(-1); }
  .scroller-con.skin_cerc .scrollbarx_bg {
    opacity: 1;
    border-radius: 0;
    background-color: rgba(50, 50, 50, 0.1);
    height: 1px;
    top: 100%;
    bottom: auto;
    margin-top: 18px; }

.scroller-con.place-scrollbar-left .scrollbary, .scroller-con.place-scrollbar-left .scrollbary_bg {
  left: auto;
  right: 100%; }

body.scroller-con {
  overflow: hidden !important;
  margin-top: 0 !important; }

body.scroller-con .inner {
  width: 100%; }

body.scroller-con.skin_blackwhite .scrollbary {
  right: 0; }

body.scroller-con.skin_blackwhite .scrollbary_bg {
  right: 5px; }

/*misc*/
.preloader {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  background: #fff url(./styleimg/preloader.gif) center no-repeat; }

.fullwidth {
  width: 100%; }

.fullheight {
  height: 100%; }

/*# sourceMappingURL=scroller.css.map */
